import { DateInput, Edit, SimpleForm, ReferenceField, TextField, TextInput, Image, ImageField, BooleanInput, Create, FileField, ImageInput } from 'react-admin';
import Grid from '@mui/material/Grid';

export const StyleTransferGalleryCreate = () => (
    <Create>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
							
							<ImageInput source="image" sx={{ '& img': { maxWidth: 150, maxHeight: 150, objectFit: 'contain' } }}/>
            </Grid>

            <Grid item xs={6}>
              <TextInput source="order" fullWidth />
              <TextInput source="name" fullWidth />
              <BooleanInput source="draft" fullWidth />
            </Grid>

          </Grid>
        </SimpleForm>
    </Create>
);

export default StyleTransferGalleryCreate;
