import React, { useState, useMemo, useContext } from 'react';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../Tooltip';
import {
  convertToHtml,
  parentheseSplit,
} from '../../helpers/importanceHelpers';

import {
  GENERATION_STATUS,
  updateStyleImageUrl,
} from '../../reducers/formReducer';
import SpinnerLoader from '../base/SpinnerLoader';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import { ImageViewerContext } from './context';
import DownloadImage from '../../services/ImageDownloader';
import LoadingButton from '../base/LoadingButton';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SparkleIconV4 } from '../../assets/SparkleV4.svg';
import { ReactComponent as CopyIcon } from '../../assets/CopyIcon.svg';
import { ReactComponent as VectorizeIcon } from '../../assets/VectorizeIconLightGray.svg';
import { ReactComponent as BookmarkIcon } from '../../assets/BookmarkIconGray.svg';
import { ReactComponent as BookmarkIconGreen } from '../../assets/BookmarkIconGreenOutline.svg';
import { ReactComponent as DownloadIcon } from '../../assets/DownloadIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/ThumbsUpOutline.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/ThumbsDownOutline.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as ChevronDownV4 } from '../../assets/ChevronDownV4.svg';
import { ReactComponent as UpscaleIcon } from '../../assets/upscale-icon.svg';
import { ReactComponent as RemoveBackgroundIcon } from '../../assets/RemoveBG.svg';
import { ReactComponent as UseAsInputImageIcon } from '../../assets/UseInputImage.svg';
import { ReactComponent as UseAsStyleImageIcon } from '../../assets/UseStyleImage.svg';
import { ReactComponent as CleanIcon } from '../../assets/CleanV4.svg';
import { SDColors } from '../redesign/constants';
import { V4_STYLES } from '../input/v4-content-styles';
import ImageComparisonV4 from '../ImageComparisonV4';
import { twMerge } from 'tailwind-merge';

const V4ImageViewer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const user = useSelector((state) => state.userSlice.user);

  const {
    viewingImage,
    actions: {
      handleNext,
      handlePrevious,
      onClose,

      handleOpenTool,
      copyToClipboard,
      handleBookmarkClicked,
      handleDeleteGeneration,
      handleReuse,
      handleFeedback,
      handleUseAsInputImage,
    },
    config: {
      isShowBookmark = true,
      isShowDelete = true,
      isShowLike = true,
      isShowDislike = true,
      isShowUpscale = true,
    },
  } = useContext(ImageViewerContext);

  const { mode: currentMode, method: currentMethod } = useGenerationEndpoint();
  const {
    mode,
    method: imageMethod,
    generationStyle,
  } = useGenerationEndpoint({
    method: viewingImage?.method,
  });

  const [showVectorizeSpinner, setShowVectorizeSpinner] = useState(false);
  const [showUpscalerSpinner, setShowUpscalerSpinner] = useState(false);
  const [showRemoveBgSpinner, setShowRemoveBgSpinner] = useState(false);
  const [isShowComparison, setIsShowComparison] = useState(false);

  const promptValue = useMemo(
    () =>
      convertToHtml(parentheseSplit(viewingImage?.prompt || ''), {
        editable: false,
      }),
    [viewingImage]
  );

  const imageStyle = useMemo(() => {
    return V4_STYLES.find((style) => style.id === viewingImage?.style);
  }, [viewingImage]);
  const ImageStyleIcon = useMemo(() => {
    return imageStyle?.icon_preview ?? imageStyle?.icon ?? 'div';
  }, [imageStyle]);

  const handleCompareInputImage = () => {
    setIsShowComparison(!isShowComparison);
  };

  if (!viewingImage) {
    return (
      <Transition.Child
        as={React.Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Dialog.Panel
          className='w-auto h-auto p-[23px] bg-[#13131A] rounded-[22px] flex flex-col  lg:flex-row gap-[29px] overflow-x-hidden overflow-y-auto relative custom-scroll'
          onClick={(e) => e.stopPropagation()}
        ></Dialog.Panel>
      </Transition.Child>
    );
  }

  return (
    <Transition.Child
      as={React.Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 scale-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-95'
    >
      <Dialog.Panel
        className='w-auto h-auto p-[23px] bg-[#13131A] rounded-[22px] flex flex-col  lg:flex-row gap-[29px] overflow-x-hidden overflow-y-auto relative custom-scroll'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='rounded-[10px] relative flex items-center justify-center aspect-square lg:w-[512px] xl:w-[600px] 2xl:w-[700px] 3xl:w-[850px] 4xl:w-[1024px]'>
          {[GENERATION_STATUS.GENERATING, GENERATION_STATUS.IN_QUEUE].indexOf(
            viewingImage?.status
          ) === -1 && (
            <div className='relative w-full h-full'>
              {isShowComparison && viewingImage?.inputImage ? (
                <ImageComparisonV4
                  className='z-30'
                  beforeImage={viewingImage?.inputImage}
                  afterImage={viewingImage?.imageUrl}
                />
              ) : (
                <img
                  src={viewingImage?.imageUrl}
                  key={viewingImage?.imageUrl}
                  alt={`Image: ${viewingImage?.prompt}`}
                  className='rounded-lg z-10 max-w-full w-full'
                />
              )}
              <span className={`absolute right-4 top-4 flex flex-row z-40`}>
                <Tooltip title='Download'>
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                    onClick={(e) =>
                      DownloadImage.download('raster', viewingImage, {
                        element: e,
                        user,
                        imageName: viewingImage?.prompt,
                      })
                    }
                  >
                    <DownloadIcon
                      className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                    />
                  </LoadingButton>
                </Tooltip>
              </span>
              <span className={`absolute right-4 bottom-4 flex flex-row z-40`}>
                <Tooltip title='Delete'>
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                    onClick={(e) => handleDeleteGeneration(viewingImage)}
                  >
                    <CleanIcon
                      className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                    />
                  </LoadingButton>
                </Tooltip>
              </span>
              <div className='absolute left-4 bottom-4 flex flex-row gap-[8px] z-40'>
                <Tooltip title='Like'>
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                    onClick={() => handleFeedback(1)}
                  >
                    <ThumbsUpIcon
                      className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${
                        viewingImage?.feedback > 0
                          ? '[&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50'
                          : '[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
                      }`}
                    />
                  </LoadingButton>
                </Tooltip>
                <Tooltip title='Dislike'>
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                    onClick={() => handleFeedback(-1)}
                  >
                    <ThumbsDownIcon
                      className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[15px] lg:h-[15px]  ${
                        viewingImage?.feedback < 0
                          ? '[&_path]:fill-dislike group-hover:[&_path]:fill-dislike/50'
                          : '[&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]'
                      } `}
                    />
                  </LoadingButton>
                </Tooltip>
                {viewingImage?.inputImage && (
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] pt-2 pl-2 pb-2 pr-1 flex items-center space-x-2 rounded-[6px] justify-center cursor-pointer group
												group-hover:flex-grow group-hover:space-x-4 group-hover:justify-start aspect-auto group-hover:aspect-auto `}
                    style={{
                      minWidth: '32px',
                      height: '32px',
                      transition: 'width 0.3s',
                    }}
                    errorMessage='Failed to load image'
                    onClick={() => {}}
                  >
                    <div className='w-auto h-auto'>
                      <Switch
                        checked={isShowComparison}
                        onChange={handleCompareInputImage}
                        disabled={!viewingImage?.inputImage}
                        className={`relative inline-flex h-[1.125rem] w-8 items-center rounded-[0.5rem] ui-checked:bg-app-green ui-not-checked:bg-[#838387]`}
                      >
                        <span className='inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-[0.125rem] uppercase '></span>
                      </Switch>
                    </div>

                    <span
                      className={`max-w-0 overflow-hidden whitespace-nowrap transition-all duration-700 ease-in-out group-hover:max-w-[180px] text-[12px] text-[#4A4A4A]`}
                    >
                      Compare Input Image
                    </span>
                  </LoadingButton>
                )}
              </div>
              <span className={`absolute left-4 top-4 flex flex-row z-40`}>
                <Tooltip
                  title={
                    viewingImage?.bookmarked ? 'Remove bookmark' : 'Bookmark'
                  }
                >
                  <LoadingButton
                    className={`bg-white/80 shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] rounded-[6px] w-[32px] h-[32px] flex items-center justify-center cursor-pointer aspect-square group`}
                    onClick={(e) => handleBookmarkClicked(viewingImage)}
                  >
                    {viewingImage?.bookmarked ? (
                      <BookmarkIconGreen
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-app-green group-hover:[&_path]:fill-app-green/50`}
                      />
                    ) : (
                      <BookmarkIcon
                        className={`max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[19px] lg:h-[19px] [&_path]:fill-[#838387] group-hover:[&_path]:fill-[#424248]`}
                      />
                    )}
                  </LoadingButton>
                </Tooltip>
              </span>
            </div>
          )}

          <button
            className={`flex justify-center items-center rounded-[7px] ${
              isShowComparison ? 'w-[22px]' : 'w-[32px]'
            }  h-[32px] bg-[#FFFFFF66] shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] absolute top-1/2 left-4 -translate-y-1/2  z-40 group`}
            onClick={() => handlePrevious()}
          >
            <ChevronDownV4 className='rotate-180 w-4 h-4 group-hover:[&_path]:fill-[#424248]' />
          </button>

          <button
            className={`flex justify-center items-center rounded-[7px] ${
              isShowComparison ? 'w-[22px]' : 'w-[32px]'
            } h-[32px] bg-[#FFFFFF66] shadow-[rgba(0,0,15,0.5)_2px_2px_2px_0px] absolute top-1/2 right-4 -translate-y-1/2 z-40 group`}
            onClick={() => handleNext()}
          >
            <ChevronDownV4 className='w-4 h-4 group-hover:[&_path]:fill-[#424248]' />
          </button>

          <SpinnerLoader
            isLoading={
              showUpscalerSpinner ||
              showVectorizeSpinner ||
              showRemoveBgSpinner ||
              [
                GENERATION_STATUS.GENERATING,
                GENERATION_STATUS.IN_QUEUE,
              ].indexOf(viewingImage?.status) > -1
            }
            classNames={{
              backdrop: '!rounded-lg',
            }}
            icon={
              {
                ...(showVectorizeSpinner && {
                  icon: <VectorizeIcon className='w-8 h-8' />,
                }),
                ...(showUpscalerSpinner && {
                  icon: <UpscaleIcon className='w-8 h-8' />,
                }),
                ...(showRemoveBgSpinner && {
                  icon: <RemoveBackgroundIcon className='w-8 h-8' />,
                }),
              }.icon
            }
          />
        </div>

        <div className='flex flex-row flex-wrap lg:flex-nowrap lg:flex-col lg:overflow-y-auto -mr-[10px] custom-scroll grow w-[346px] max-w-[346px] gap-4 lg:justify-start '>
          <div className='grow basis-full lg:grow-0 lg:basis-auto'>
            <div className='flex justify-end absolute right-[22px] top-[22px] z-30'>
              <Tooltip title='Close'>
                <button
                  className='w-[28px] h-[28px] flex items-center justify-center bg-divider rounded-md'
                  onClick={(e) => onClose()}
                >
                  <CloseIcon />
                </button>
              </Tooltip>
            </div>

            <div className=' flex flex-col gap-[18px]'>
              <p className='font-semibold text-[16px] text-title-white flex items-center gap-2'>
                Prompt:
                <Tooltip title='Copy prompt'>
                  <CopyIcon
                    className='cursor-pointer [&_path]:fill-editor-label'
                    onClick={(e) => copyToClipboard(viewingImage?.prompt)}
                  />
                </Tooltip>
              </p>

              <p
                className='text-editor-label text-start text-[14px]'
                dangerouslySetInnerHTML={{ __html: promptValue }}
              ></p>

              {viewingImage?.negativePrompt && (
                <p
                  className='text-[13px] text-editor-labelfile:///C:/ProgramData/pbtmp245.html
				 text-start'
                >
                  Negative Prompt: {viewingImage?.negativePrompt}
                </p>
              )}

              {isDevModeEnabled && (
                <>
                  <hr className='border-divider' />
                  <p className='text-xs text-start text-white'>
                    <span className='text-text-secondary'>
                      Internal Positive Prompt:{' '}
                    </span>
                    {viewingImage?.internals?.injectedPrompt}
                  </p>
                  <p className='text-xs text-start text-white'>
                    <span className='text-text-secondary '>
                      Internal Negative Prompt:{' '}
                    </span>
                    {viewingImage?.internals?.injectedNegativePrompt}
                  </p>
                </>
              )}

              {/* <ul className='flex gap-[9px] flex-wrap '>
                {[
                  STYLES[imageMethod]?.find(
                    (item) => item.id === viewingImage?.contentStyle
                  )?.name,
                  ...(viewingImage?.promptHelpers ||
                    viewingImage?.styles ||
                    []),
                ]
                  .filter((item) => !!item)
                  .map((style) => (
                    <li
                      key={`image-viewer-style-${style}`}
                      className='bg-app-green text-[#08432B] text-[10px] leading-[24px] tracking-wide rounded-[17px] px-[8px] font-bold'
                    >
                      {style}
                    </li>
                  ))}
              </ul> */}
            </div>
          </div>

          <div className='grid grid-cols-2 grid-rows-2 gap-[16px]'>
            {isShowUpscale && (
              <button
                className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
                onClick={() => handleOpenTool('upscale')}
              >
                <span className='flex items-center justify-start gap-[10px]'>
                  <UpscaleIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                  Upscale Image
                </span>
              </button>
            )}
            <button
              className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
              onClick={() => handleOpenTool('vectorizer')}
            >
              <span className='flex items-center justify-start gap-[10px]'>
                <VectorizeIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                Vectorize Image
              </span>
            </button>

            <button
              className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
              onClick={handleReuse}
            >
              <span className='flex items-center justify-start gap-[10px]'>
                <SparkleIconV4 className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                Reuse Settings
              </span>
            </button>
            <button
              className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
              onClick={() => handleOpenTool('bg-remover')}
            >
              <span className='flex items-center justify-start gap-[10px]'>
                <RemoveBackgroundIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                Remove BG
              </span>
            </button>
            <button
              className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
              onClick={handleUseAsInputImage}
            >
              <span className='flex items-center justify-start gap-[10px]'>
                <UseAsInputImageIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                Use as input image
              </span>
            </button>
            <button
              className='w-[164px] h-[45px] bg-divider pl-[7px] rounded-[4px] text-[12px] text-title-white font-semibold'
              onClick={() => {
                dispatch(updateStyleImageUrl(viewingImage?.imageUrl));
                onClose();
              }}
            >
              <span className='flex items-center justify-start gap-[10px]'>
                <UseAsStyleImageIcon className='max-lg:aspect-square max-lg:h-5 max-lg:w-5 lg:w-[20px] lg:h-[20px] [&_path]:fill-active-icon group-hover:[&_path]:fill-app-green' />
                Use as style image
              </span>
            </button>
          </div>

          <div className='flex flex-col w-full h-auto gap-[18px]'>
            <p className='font-semibold text-[16px] text-title-white col-span-2 flex items-center gap-2'>
              Image Settings:
            </p>
            <div className='flex flex-wrap h-auto w-full gap-[12px]'>
              <div className='flex flex-col items-center justify-center h-[45px] px-[7px] border border-overlay-bg rounded-[8px]'>
                <p className='text-style-text text-[12px] font-bold '>Model</p>
                <p className='text-[#9393A3] text-[12px] flex items-center'>
                  V4.0
                  {(imageStyle?.a_endpoint ||
                    imageStyle?.b_endpoint ||
                    imageStyle?.c_endpoint) && (
                    <div className='bg-app-green text-switch-label-green aspect-square w-4 rounded-sm mx-1 font-bold text-xs '>
                      {imageStyle?.a_endpoint === viewingImage?.method && ' A'}
                      {imageStyle?.b_endpoint === viewingImage?.method && ' B'}
                      {imageStyle?.c_endpoint === viewingImage?.method && ' C'}
                    </div>
                  )}
                </p>
              </div>
              <div className='relative flex items-center justify-between h-[45px] pr-[7px] gap-[5px] border border-overlay-bg rounded-[8px]'>
                <div className='flex flex-col h-full justify-start'>
                  <div className='w-[24px] h-[24px] rounded-tl-lg rounded-br-lg overflow-hidden'>
                    <ImageStyleIcon className={twMerge('w-full h-full', imageStyle?.icon_preview_classes)} />
                  </div>
                </div>
                <div className='flex flex-col'>
                  <p className='text-style-text text-[12px] font-bold'>Style</p>
                  <div className='flex items-center justify-start'>
                    <p className='text-[#9393A3] text-[12px] capitalize'>
                      {imageStyle?.name ||
                        viewingImage?.style.replace(/[-_]/g, ' ')}
                    </p>
                  </div>
                </div>
              </div>
              {viewingImage?.styleConfig?.background?.colors[0] && (
                <div className='flex flex-col items-center justify-center h-[45px] px-[7px] border border-overlay-bg rounded-[8px] gap-[2px]'>
                  <p className='text-style-text text-[12px] font-bold'>
                    BG Color
                  </p>
                  <div
                    className='flex items-center justify-start rounded-[4px] h-[15px] w-full border border-overlay-bg'
                    style={{
                      backgroundColor: viewingImage?.styleConfig?.background?.colors[0]?.color ?? SDColors.find(
                        ({ name }) =>
                          name ===
                          viewingImage?.styleConfig?.background?.colors[0]
                      )?.hex,
                    }}
                  ></div>
                </div>
              )}
              {viewingImage?.styleConfig &&
                (viewingImage?.styleConfig?.outline_stroke?.colors[0] ||
                  viewingImage?.styleConfig?.palette?.colors[0] ||
                  viewingImage?.styleConfig?.palette?.colors[1]) && (
                  <div className='flex flex-col items-center justify-center h-[45px] px-[7px] border border-overlay-bg rounded-[8px] gap-[2px]'>
                    <p className='text-style-text text-[12px] font-bold'>
                      Color Palette
                    </p>
                    <div className='flex items-center justify-center h-[15px] w-full gap-[5px]'>
                      {viewingImage?.styleConfig?.outline_stroke?.colors[0] && (
                        <div
                          className='w-[15px] h-[15px] rounded-[4px] border border-overlay-bg'
                          style={{
                            backgroundColor: viewingImage?.styleConfig?.outline_stroke
                                  ?.colors[0]?.color ?? SDColors.find(
                              ({ name }) =>
                                name ===
                                viewingImage?.styleConfig?.outline_stroke
                                  ?.colors[0]
                            )?.hex,
                          }}
                        ></div>
                      )}
                      {viewingImage?.styleConfig?.palette?.colors[0] && (
                        <div
                          className='w-[15px] h-[15px] rounded-[4px] border border-overlay-bg'
                          style={{
                            backgroundColor: viewingImage?.styleConfig?.palette?.colors[0]?.color ??
															SDColors.find(
																({ name }) =>
																	name ===
																	viewingImage?.styleConfig?.palette?.colors[0]
															)?.hex,
                          }}
                        ></div>
                      )}
                      {viewingImage?.styleConfig?.palette?.colors[1] && (
                        <div
                          className='w-[15px] h-[15px] rounded-[4px] border border-overlay-bg'
                          style={{
                            backgroundColor: viewingImage?.styleConfig?.palette?.colors[1]?.color ??
															SDColors.find(
																({ name }) =>
																	name ===
																	viewingImage?.styleConfig?.palette?.colors[1]
															)?.hex,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                )}
              <div className='flex flex-col items-center justify-center h-[45px] px-[7px] border border-overlay-bg rounded-[8px]'>
                <p className='text-style-text text-[12px] font-bold'>
                  Resolution
                </p>
                <div className='flex items-center justify-start'>
                  <p className='text-[#9393A3] text-[12px]'>
                    {viewingImage?.dimension}
                  </p>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center h-[45px] px-[7px] border border-overlay-bg rounded-[8px]'>
                <p className='text-style-text text-[12px] font-bold'>Seed</p>
                <div className='flex items-center justify-start'>
                  <p className='text-[#9393A3] text-[12px]'>
                    {viewingImage?.seed}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap h-auto w-full gap-[12px] -mt-[5px]'>
              {viewingImage?.inputImage && (
                <div className='flex flex-row items-center justify-start h-[45px] w-auto xl:h-[60px] xl:w-[162px] 4xl:w-[162px] 4xl:h-[80px] pr-[7px] border border-overlay-bg rounded-[8px] overflow-hidden gap-[7px]'>
                  <img
                    src={viewingImage?.inputImage}
                    className='w-[45px] h-[45px] xl:h-[60px] xl:w-[60px] 4xl:w-[80px] 4xl:h-[80px] round-l-[8px]'
                    alt=''
                  />
                  <div className='flex flex-col gap-[3px] xl:gap-[6px] 3xl:gap-[10px]'>
                    <p className='text-style-text text-[12px] font-roboto font-bold'>
                      Input Image
                    </p>
                    <div className='flex items-center justify-start'>
                      <p className='text-[#9393A3] text-[10px] font-roboto'>
                        Strength: {viewingImage?.imageStrength}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {viewingImage?.styleImage && (
                <div className='flex flex-row items-center justify-start h-[45px] w-auto xl:h-[60px] xl:w-[162px] 4xl:w-[162px] 4xl:h-[80px] pr-[7px] border border-overlay-bg rounded-[8px] overflow-hidden gap-[7px]'>
                  <img
                    src={viewingImage?.styleImage}
                    className='w-[45px] h-[45px] xl:h-[60px] xl:w-[60px] 4xl:w-[80px] 4xl:h-[80px] round-l-[8px]'
                    alt=''
                  />
                  <div className='flex flex-col gap-[3px] xl:gap-[6px] 3xl:gap-[10px]'>
                    <p className='text-style-text text-[12px] font-roboto font-bold'>
                      Style Image
                    </p>
                    <div className='flex items-center justify-start'>
                      <p className='text-[#9393A3] text-[10px] font-roboto'>
                        Strength: {viewingImage?.styleStrength}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Transition.Child>
  );
};

export default V4ImageViewer;
