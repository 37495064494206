import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import ProtectedRoute from '../../components/ProtectedRoute';
import AppLayout from '../../layouts/AppLayout';

import PromptInput from '../../components/input/PromptInput';
import Generations from '../../components/generation/Generations';
import ImageViewer from '../../components/ImageViewer';

import { ReactComponent as EditCanvasIcon } from '../../assets/EditorIcon.svg';
import { ReactComponent as UploadImageIcon } from '../../assets/UploadImageIcon.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrow-back.svg';
import { ReactComponent as AdvancedSettingIcon } from '../../assets/advancedsetting.svg';
import ImageEditor from '../../components/editor/ImageEditor';
import TutorialModal from '../../components/modals/TutorialModal';
import QuestionnaireModal from '../../components/modals/QuestionnaireModal';
import OutOfCredits from '../../components/modals/OutOfCredits';
import GraphicEditor from '../../components/editor/GraphicEditor';
import GenerationHistory from '../../components/generation/GenerationHistory';
import { useWindowDimensions } from '../../hooks/useDimenssion';
import { breakpoints } from '../../constant/device';
import { EditorProvider } from '../../context';
import GeneratedImageListWidget from '../../components/GeneratedImageListWidget';
import AdvancedSettings from '../../components/redesign/AndvancedSettings';
import Redesign from '../../components/redesign/Redesign';
import ImageUpload from '../../components/ImageUpload';
import {
  DEFAULT_COLORS,
  setBackgroundImage,
  setIsEditImage,
  setPresetColor,
} from '../../reducers/imageEditorReducer';
import { emptyJPEG } from '../../components/editor/image_generator';
import { updateInputImage } from '../../reducers/formReducer';
import QueueStatus, {
  useQueueVisibility,
} from '../../components/queue/QueueStatus';
import useGenerationEndpoint from '../../components/input/useGenerationEndpoint';
import V4GenerationHistory from '../../components/generation/V4GenerationHistory';
import GenerationsV4 from '../../components/generation/GenerationsV4';
import RedesignV4 from '../../components/redesign/RedesignV4';
import V3ImagePipeline from '../../components/image-to-image/V3';
import V4ImagePipeline from '../../components/image-to-image/V4';

const ImagePipeline = (props) => {
  const { mode: version } = useGenerationEndpoint();

  return (
    <AppLayout>
      <ProtectedRoute>
        <Helmet>
          <title>Generator | Logo Diffusion</title>
        </Helmet>
        <EditorProvider>
					{version === 'v4.0' ? (
						<V4ImagePipeline />
					) : (
						<V3ImagePipeline />
					)}

          <ImageViewer />

          {/* <ImageEditor /> */}

          <OutOfCredits />
        </EditorProvider>
      </ProtectedRoute>
    </AppLayout>
  );
};

export default ImagePipeline;
