import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DownloadImage from '../../services/ImageDownloader';
import { toDataURL } from '../ImageUploadV4';

import {
  bgRemove,
  bookmarkImage,
  closeImageModal,
  deleteImage,
  loadSettings,
  setImageAsInspiration,
  showImageModal,
  upscaleImage,
  vectorizeImage,
  submitFeedback,
  updateContentStyle,
  GENERATION_STATUS,
  updateInputImage,
} from '../../reducers/formReducer';

import { Dialog, Transition } from '@headlessui/react';
import useGenerationEndpoint from '../input/useGenerationEndpoint';
import DepImageViewer from './DepImageViewer';
import { ImageViewerContext } from './context';
import V4ImageViewer from './V4ImageViewer';
import { useNavigate } from 'react-router-dom';
import { setBackgroundImage } from '../../reducers/imageEditorReducer';

function handleRasterDownload(image, dispatch, opts) {
  DownloadImage.download('raster', image, opts);
}

function ImageViewer(props) {
  const { toolOptions = {}, deleteItemInList, updateItemInList } = props;
  const {
    isShowBookmark = true,
    isShowDelete = true,
    isShowEdit = true,
    isShowLike = true,
    isShowDislike = true,
    isShowUpscale = true,
  } = toolOptions;

  const navigate = useNavigate();

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userSlice.user);

  const viewingImageUrl = useSelector((state) => state.form.viewingImageUrl);
  const default_generations = useSelector((state) => state.form.generations);

  const generations = useMemo(
    () => props.generatedImages || default_generations,
    [default_generations, props]
  );

  const isOpen = useSelector((state) => state.form.showImageViewer);

  const viewingImageIndex = useMemo(() =>
    generations.findIndex(
      (item) => item.imageUrl === viewingImageUrl,
      [viewingImageUrl, generations]
    )
  );
  const viewingImage = useMemo(() =>
    generations.find(
      (item) => item.imageUrl === viewingImageUrl,
      [viewingImageUrl, generations]
    )
  );

  const setViewingImage = (imageUrl) => {
    dispatch(showImageModal(imageUrl));
  };

  const setViewingImageIndex = (index) => {
    setViewingImage(
      generations[(index + generations.length) % generations.length].imageUrl
    );
  };

  const onClose = () => {
    dispatch(closeImageModal());
  };

  const { mode: currentMode, method: currentMethod } = useGenerationEndpoint();
  const {
    mode,
    method: imageMethod,
    generationStyle,
  } = useGenerationEndpoint({ method: viewingImage?.method });

  const handleSwitch = (newIndex) => {
    if (newIndex < 0 || newIndex === generations.length) return;

    if (!generations[newIndex].imageUrl) return;

    setViewingImageIndex(newIndex);
  };

  const handleOpenTool = (toolName) => {
    navigate(
      `/${toolName}?generationId=${viewingImage._id}&fileName=${viewingImage.prompt}&imageUrl=${viewingImage.imageUrl}`
    );
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast('Prompt copied.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const handleBookmarkClicked = async () => {
    try {
      const resp = await dispatch(
        bookmarkImage({
          _id: viewingImage._id,
          bookmarked: !viewingImage.bookmarked,
        })
      ).unwrap();

      if (props.updateItemInList) props.updateItemInList(resp);
    } catch (e) {
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleDeleteGeneration = async () => {
    try {
      await dispatch(deleteImage(viewingImage._id)).unwrap();
      onClose();
      if (props.deleteItemInList) props.deleteItemInList(viewingImage);
    } catch (e) {
      toast('Something went wrong', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleReuse = async () => {
    try {
      await dispatch(loadSettings(viewingImage)).unwrap();
      onClose();
      props?.gotoTextWorkspace?.();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to load image settings', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleFeedback = async (feedback) => {
    try {
      await dispatch(
        submitFeedback({
          id: viewingImage._id,
          feedback: feedback === viewingImage.feedback ? 0 : feedback,
        })
      ).unwrap();
    } catch (e) {
      console.log(e);
      toast(e?.error?.detail || 'Failed to submit feedback', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000,
      });
    }
  };

  const handleUseAsInputImage = () => {
    const image = new Image();

    image.onload = () => {
      toDataURL(viewingImage.imageUrl, (base64) => {
        dispatch(updateInputImage(base64));
        dispatch(setBackgroundImage(base64));
      });
    };

    image.src = viewingImage.imageUrl;

		onClose();
  };

  return (
    <ImageViewerContext.Provider
      value={{
        open: isOpen,

        viewingImageIndex,
        viewingImageUrl,
        viewingImage,

        generations,

        config: {
          ...toolOptions,
        },

        actions: {
          handleNext: () => handleSwitch(viewingImageIndex + 1),
          handlePrevious: () => handleSwitch(viewingImageIndex - 1),
					onClose,
					handleOpenTool,
					copyToClipboard,
					handleBookmarkClicked,
					handleDeleteGeneration,
					handleReuse,
					handleFeedback,
					handleUseAsInputImage,
        },
      }}
    >
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog open={isOpen} onClose={onClose} className='relative z-50'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto custom-scroll'>
            <div className='flex min-h-full items-center justify-center p-4 text-center '>
              {mode === 'v4.0' ? <V4ImageViewer /> : <DepImageViewer />}
            </div>
          </div>
        </Dialog>
      </Transition>
    </ImageViewerContext.Provider>
  );
}

export default ImageViewer;
