import { useState, useEffect } from "react";

import Tooltip from "./Tooltip";

import { ReactComponent as CaretLeftDarkGray } from '../assets/CaretLeftDarkGray.svg';
import { ReactComponent as CaretLeftLightGray } from '../assets/CaretLeftLightGray.svg';
import { ReactComponent as CaretRightLightGray } from '../assets/CaretRightLightGray.svg';
import { ReactComponent as CaretRightDarkGray } from '../assets/CaretRightDarkGray.svg';
import { ReactComponent as InfoIcon } from '../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from "react-redux";
import { loadGenerationHistory, showImageModal } from "../reducers/formReducer";
import { toast } from "react-toastify";
import { throttle } from "lodash";

function GeneratedImageListWidget(props) {
	const dispatch = useDispatch();

  const generatedImages = useSelector(state => state.form.generations);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(window.innerWidth < 768 ? 4 : window.innerWidth < 1024 ? 8 : 9);

  const handleViewImage = (image) => {
    dispatch(showImageModal(image.imageUrl));
  };

  const loadMore = async () => {
    try {
      await dispatch(loadGenerationHistory()).unwrap()

    } catch(e) {
      console.log(e)
      toast('Failed to load generation history', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });
    }
  }

	const changePage = (newPage) => {
		if (Math.ceil(generatedImages.length / perPage) > page + 1) {
			loadMore();
		}

		setPage(newPage);
	}

  useEffect(() => {
		const onResize = () => {
      setPerPage(window.innerWidth < 768 ? 4 : window.innerWidth < 1024 ? 8 : 9);
    }
    window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		}
  }, []);

	useEffect(() => {
		loadMore();
	}, []);


  return (
    <div className="bg-app-bg-gray border border-gray-800 rounded-2xl py-2 pl-2 pr-3 lg:p-4 height">
      <div className="flex lg:flex-col gap-2">
        <div className="flex flex-col lg:flex-row gap-1 items-center justify-center lg:items-center lg:justify-start">
          <h1 className='text-white font-semibold text lg:flex gap-1 items-center hidden'>
            Generation History
            <Tooltip title="This is the history of your previous image generations.">
              <InfoIcon />
            </Tooltip>
          </h1>
          <div className="flex gap-1 items-center mr-1">
            {page === 0 ? <CaretLeftDarkGray /> : <CaretLeftLightGray onClick={e => changePage(page - 1)} className="cursor-pointer" />}
            {Math.ceil(generatedImages.length / perPage) > page + 1 ? <CaretRightLightGray onClick={e => changePage(page + 1)} className="cursor-pointer" /> : <CaretRightDarkGray />}
          </div>

          <Tooltip title="This is the history of your previous image generations.">
            <InfoIcon className="lg:hidden"/>
          </Tooltip>
        </div>

        <div className="flex gap-3 lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-[10px]">
          {generatedImages.slice(page * perPage, (page + 1) * perPage).map((generatedImage, i) => (
            <img
              src={generatedImage.imageUrl}
              alt={`generated-thumbnail-${i}`}
              key={`generated-thumbnail-${i}-${generatedImage.imageUrl}`}
              className="h-[50px] w-[50px] lg:h-[75px] lg:w-[75px] rounded-lg cursor-pointer aspect-square"
              onClick={e => handleViewImage(generatedImage)}
            />
          ))}

          {generatedImages.slice(page * perPage, (page + 1) * perPage).length < 9 && [...Array(perPage - generatedImages.slice(page * perPage, (page + 1) * perPage).length)].map((i, index) => (
            <span key={`generated-placeholder-${index}`} className="h-[50px] w-[50px] lg:h-[75px] lg:w-[75px] border border-[#31313E] border-dashed rounded-lg aspect-square "></span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GeneratedImageListWidget;
