import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import TextAreaWithImportance from './TextAreaWithImportance';
import { RadioGroup, Switch } from '@headlessui/react';

import { convertToHtml, setImportance } from '../../helpers/importanceHelpers';
import { ReactComponent as Lightning } from '../../assets/Lightning.svg';
import { ReactComponent as Method2DIcon } from '../../assets/2d-icon-new.svg';
import { ReactComponent as Method3DIcon } from '../../assets/3d-icon-new.svg';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as GridIcon } from '../../assets/GridIcon.svg';
import { ReactComponent as ListIcon } from '../../assets/ListIcon.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  updatePrompt,
  updateMethod,
  removePromptHelper,
  partialUpdateStatePayload,
  clearPromptForceUpdate,
  updateShowPromptTutorial,
  updateInputImage,
  setStyleConfig,
  updateStyleImage,
} from '../../reducers/formReducer';
import { setBackgroundImage } from '../../reducers/imageEditorReducer';
import GenerateButton from './GenerateButton';
import StyleSelector from './StyleSelector';
import { usePopper } from 'react-popper';
import environment from '../../helpers/envProvider';
import V3Controls from './V3Controls';
import useGenerationEndpoint from './useGenerationEndpoint';
import AdvancedSettingPopup from './AdvancedSettingPopup';
import useCurrentWorkspace from '../../hooks/useCurrrentWorkspace';
import STYLES, { NoneIcon, V3_GROUPS } from './content-styles';
import { SDColors } from '../redesign/constants';
import { CONFIG_PROPS } from './style-config/constants';

const ENVIRONMENT = environment('REACT_APP_ENVIRONMENT') || 'production';

const color_keys = ['palette', 'outline_stroke'];

function PromptInput(props) {
  const { generationRef, setMode, fixedPosition = true } = props;

  const dispatch = useDispatch();
  // const { contentStyleHelpers, handleContentStyleHelperRemoved } = props;
  const denoisingStrength = useSelector(
    (state) => state.form.payload.denoisingStrength
  );

  const showTutorial = useSelector((state) => state.form.showPromptTutorial);

  const setShowTutorial = (val) => {
    dispatch(updateShowPromptTutorial(val));
  };

  // const [showTutorial, setShowTutorial] = useState(localStorage.getItem('hidePromptTutorial') !== 'true')

  const [isGridView, setIsGridView] = useState(false);

  useEffect(() => {
    props?.onViewChange?.(isGridView);
  }, [isGridView]);

  const [sliderValue, setSlideValue] = useState(denoisingStrength);

  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();
  const [promptEl, setPromptEl] = useState();
  let tutorialPopper = usePopper(promptEl, tutorialEl, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [40, 16],
        },
      },
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: {
            right: 270,
          },
          // padding: ({ popper, reference, placement }) => ({
          // right: popper.width - 2 * reference.width
          // }),
        },
      },
    ],
  });

  const hanldeDenoisingStrength = (event) => {
    setSlideValue(event.target.value);
    dispatch(
      partialUpdateStatePayload({ denoisingStrength: event.target.value })
    );
    // dispatch(partialUpdateState(value))
  };

  const closeTutorialModal = () => {
    setShowTutorial(false);
    localStorage.setItem('hidePromptTutorial', 'true');
  };

  const prompt = useSelector((state) => state.form.payload.prompt);
  const promptUpdate = useSelector((state) => state.form.__force_update_prompt);
  const onChange = (value) => {
    dispatch(updatePrompt(value));
  };

  const method = useSelector((state) => state.form.method);
  const handleMethod = (value) => {
    dispatch(updateMethod(value));
  };

  const [value, setValue] = useState(prompt.split(' '));
  const [innerHtml, setInnerHtml] = useState(convertToHtml(value));
  const importanceRef = useRef(null);

  const isDevModeEnabled = useSelector((state) => state.form.__dev_mode);

  const { workspace } = useCurrentWorkspace();

  useEffect(() => {
    if (promptUpdate !== undefined && promptUpdate !== null) {
      let result = [''];
      let parantheses_count = 0;
      promptUpdate.split('').forEach((element) => {
        if (parantheses_count === 0 && /^\s$/g.test(element)) {
          result.push('');
          return;
        }

        if (element === '(') parantheses_count++;
        if (element === ')') parantheses_count--;

        result[result.length - 1] += element;
      });
      setValue(result);
      setInnerHtml(convertToHtml(result));
      dispatch(clearPromptForceUpdate());
    }
  }, [promptUpdate, dispatch]);

  const updateValue = (cb) => {
    setValue((val) => {
      const newValue = cb(val);

      if (newValue && newValue.length > 0) onChange(newValue.join(' '));
      else onChange('');

      setInnerHtml(convertToHtml(newValue));
      return newValue;
    });
  };

  const handleImportance = (e, importance) => {
    e.preventDefault();
    let sel = window.getSelection();

    if (sel.rangeCount < 0) return;

    let curInd = 0;
    let ret = [];
    let range = document.createRange();

    let grouping = '';

    textAreaRef.current.childNodes.forEach((el, ind) => {
      let hasIntersection = false;
      for (let i = curInd, selRange; i < sel.rangeCount; ++i) {
        selRange = sel.getRangeAt(i);
        range.selectNodeContents(el);
        if (
          selRange.compareBoundaryPoints(range.START_TO_END, range) > 0 &&
          selRange.compareBoundaryPoints(range.END_TO_START, range) < 0
        ) {
          hasIntersection = true;

          if (selRange.compareBoundaryPoints(range.START_TO_START, range) > 0) {
            range.setStart(selRange.startContainer, selRange.startOffset);
          }

          if (selRange.compareBoundaryPoints(range.END_TO_END, range) < 0) {
            range.setEnd(selRange.endContainer, selRange.endOffset);
            curInd++;
          }

          let ind = 0;

          if (el.dataset?.importance) {
            ret = [
              ...ret,
              grouping.length > 0
                ? setImportance(grouping, importance)
                : undefined,
              setImportance(el.innerText, Number(importance)),
            ].filter((item) => !!item);
            grouping = '';

            break;
          }

          (el.innerText ?? el.textContent)
            .split(' ')
            .map((item, i) => {
              const ret = { value: item, ind };

              ind = ind + item.length + 1;

              return ret;
            })
            .filter((item) => !!item.value.replace(/[\s\n]/g, ''))
            .forEach((item) => {
              let curEl = el;
              const wordRange = document.createRange();
              wordRange.setStart(curEl, item.ind);
              wordRange.setEnd(curEl, item.ind + item.value.length);

              if (
                selRange.compareBoundaryPoints(range.START_TO_END, wordRange) >
                  0 &&
                selRange.compareBoundaryPoints(range.END_TO_START, wordRange) <
                  0
              ) {
                grouping = `${grouping} ${item.value}`.trim();
              } else {
                ret = [...ret, grouping, item.value].filter((item) => !!item);
                grouping = '';
              }
            });
          break;
        }
      }
      if (!hasIntersection) {
        ret = [
          ...ret,
          grouping.length > 0 ? setImportance(grouping, importance) : undefined,
          ...[el.innerText ?? el.textContent]
            // .split(" ")
            .filter((item) => !!item.replace(/[\s\n]/g, ''))
            .map((item, ind) =>
              setImportance(
                item,
                ind === 0 ? Number(el.dataset?.importance || 0) : 0
              )
            ),
        ].filter((item) => !!item);
        grouping = '';
      }
    });

    updateValue(() =>
      [
        ...ret,
        grouping.length > 0 ? setImportance(grouping, importance) : undefined,
      ]
        .filter((item) => !!item)
        .map((x) => x.trim())
    );
  };

  const textAreaRef = useRef(null);

  const { mode, generationStyle, setGenerationStyle } = useGenerationEndpoint();

  const style = useSelector((state) => state.form.payload.style);

  const currentStyle = useMemo(
    () => STYLES[method].find((item) => item.id === style),
    [method, style]
  );

  const CurrentStyleIcon = useMemo(
    () => currentStyle?.icon_preview || currentStyle?.icon || NoneIcon,
    [currentStyle]
  );

  const inputImage = useSelector((state) => state.form.payload.inputImage);

  const backgroundColor = useSelector(
    (state) => state.form.payload.styleConfig.background
  );

  const paletteColor = useSelector(
    (state) => state.form.payload.styleConfig.palette
  );

  const yourMainText = useSelector(
    (state) => state.form.payload.styleConfig.text
  );

  const yourMainLetter = useSelector(
    (state) => state.form.payload.styleConfig.letter
  );

  const config = useMemo(
    () => CONFIG_PROPS[method]?.[style] || [],
    [style, method]
  );
  const style_config = useSelector((state) => state.form.payload.styleConfig);

  const handleRemoveInputImage = () => {
    dispatch(updateInputImage(null));
    dispatch(setBackgroundImage(null));
    if (method === 'ddd') dispatch(updateMethod('redesign'));
  };

  const handleRemoveBGColor = () => {
    if (config['background']) {
      dispatch(
        setStyleConfig({
          config_id: 'background',
          value: {
            value:
              config['background'].default ?? style_config['background'].value,
            enabled:
              config['background'].default_enabled ??
              config['background'].always_enabled ??
              true,
            colors: [],
            __randomized: true,
          },
        })
      );
    }
  };
  const handleRemovePaletteColor = () => {
    for (const key of color_keys) {
      if (config[key]) {
        dispatch(
          setStyleConfig({
            config_id: key,
            value: {
              value: config[key].default ?? style_config[key].value,
              enabled:
                config[key].default_enabled ??
                config[key].always_enabled ??
                true,
              colors: [],
              __randomized: true,
            },
          })
        );
      }
    }
  };
  const handleRemoveMainText = () => {
    if (config['text']) {
      dispatch(
        setStyleConfig({
          config_id: 'text',
          value: {
            value: config['text'].default ?? style_config['text'].value,
            enabled:
              config['text'].default_enabled ??
              config['text'].always_enabled ??
              true,
            colors: [],
            __randomized: true,
          },
        })
      );
    }
  };
  const handleRemoveMainLetter = () => {
    if (config['letter']) {
      dispatch(
        setStyleConfig({
          config_id: 'letter',
          value: {
            value: config['letter'].default ?? style_config['letter'].value,
            enabled:
              config['letter'].default_enabled ??
              config['letter'].always_enabled ??
              true,
            colors: [],
            __randomized: true,
          },
        })
      );
    }
  };

  const styleImage = useSelector((state) => state.form.payload.styleImage);
  const styleImageUrl = useSelector(
    (state) => state.form.payload.styleImageUrl
  );
  const handleRemoveStyleImage = () => {
    dispatch(updateStyleImage());
  };

  return (
    <>
      <div
        className={`flex flex-row ${
          fixedPosition
            ? 'fixed bottom-5 pr-[40px] 2xl:pl-[300px] !justify-end 2xl:!justify-center left-1/2 -translate-x-1/2 w-full '
            : 'w-full max-w-[1200px] mx-auto'
        } z-20 justify-center gap-[21px]`}
      >
        <div
          className={`relative flex flex-row bg-app-bg-gray rounded-2xl border border-app-search-gray outline-none text-gray-400  text-sm 4xl:text-base p-2 gap-2 ${
            fixedPosition
              ? 'w-11/12 md:w-[512px] lg:w-[75%] xl:w-[70%] 2xl:w-[80%] max-w-[1250px]'
              : 'w-full'
          }`}
          ref={setPromptEl}
        >
          {mode === 'v4.0' && workspace === 'text-workspace' && (
            <div className='bg-app-bg-gray absolute right-0 -top-[45px] 3xl:-top-[49px] flex items-center justify-evenly w-[79px] h-[32px] rounded-[8px]'>
              <div
                className={`w-full h-full flex items-center justify-center cursor-pointer rounded-l-[8px] ${
                  isGridView ? 'bg-overlay-bg' : ''
                }`}
                onClick={() => setIsGridView(true)}
              >
                <GridIcon
                  className={`${
                    isGridView
                      ? '[&_path]:fill-app-green'
                      : '[&_path]:fill-[#474A56]'
                  }`}
                />
              </div>
              <div
                className={`w-full h-full flex items-center justify-center rounded-r-[8px] ${
                  !isGridView ? 'bg-overlay-bg' : ''
                }`}
                onClick={() => setIsGridView(false)}
              >
                <ListIcon
                  className={`${
                    !isGridView
                      ? '[&_path]:fill-app-green'
                      : '[&_path]:fill-[#474A56]'
                  }`}
                />
              </div>
            </div>
          )}
          <Tooltip
            title={
              generationStyle.startsWith('waterfall')
                ? 'Not available on this model'
                : 'Select any word or phrase from your prompt and click on the bolts to assign more importance to it.'
            }
            className='self-center'
          >
            <div
              className='bg-app-black rounded-md outline-none text-gray-400 p-[0.4rem] flex flex-col items-center gap-[0.4rem] self-center'
              ref={importanceRef}
            >
              <button
                type='button'
                className={'peer/i-3 group'}
                disabled={generationStyle.startsWith('waterfall')}
                onClick={(e) => handleImportance(e, 3)}
              >
                {/* <Lightning className="h-3 w-[0.375rem] " /> */}
                <Lightning className='h-[0.9rem] scale-x-125 w-[0.45rem] group-hover:[&>*]:fill-app-green group-disabled:[&>*]:!fill-style-disabled' />
              </button>
              <button
                type='button'
                className={'peer/i-2 group peer-hover/i-3:[&_*]:fill-app-green'}
                disabled={generationStyle.startsWith('waterfall')}
                onClick={(e) => handleImportance(e, 2)}
              >
                {/* <Lightning className="h-3 w-[0.375rem] " /> */}
                <Lightning className='h-[0.9rem] scale-x-125 w-[0.45rem] group-hover:[&>*]:fill-app-green group-disabled:[&>*]:!fill-style-disabled' />
              </button>
              <button
                type='button'
                className={
                  'peer/i-1 group peer-hover/i-2:[&_*]:fill-app-green peer-hover/i-3:[&_*]:fill-app-green '
                }
                disabled={generationStyle.startsWith('waterfall')}
                onClick={(e) => handleImportance(e, 1)}
              >
                {/* <Lightning className="h-3 w-[0.375rem] " /> */}
                <Lightning className='h-[0.9rem] scale-x-125 w-[0.45rem] group-hover:[&>*]:fill-app-green group-disabled:[&>*]:!fill-style-disabled' />
              </button>
            </div>
          </Tooltip>
          {mode === 'v4.0' ? (
            <div className='flex flex-col w-full'>
              <TextAreaWithImportance
                value={value}
                placeholder='Add your text here, describing the content you want to generate.'
                updateValue={updateValue}
                innerHtml={innerHtml}
                textAreaRef={textAreaRef}
                importanceRef={importanceRef}
              />
              <div className='w-full overflow-hidden pt-0 pb-0'>
                <div className='flex w-full items-center justify-start overflow-y-hidden overflow-x-auto gap-[11px] custom-scroll prompt-custom-scroll'>
                  {yourMainText?.value && (
                    <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between pr-[5px] shrink-0'>
                      <div className='flex items-center w-auto h-full max-w-[80px] bg-app-green rounded-l-[8px] px-[5px]'>
                        <p className='whitespace-nowrap truncate text-[12px] text-[#08432B] pl-[2px]'>
                          {yourMainText.value}
                        </p>
                      </div>
                      <p className='font-roboto text-style-text text-[12px]'>
                        {
                          {
                            'logo-type': 'Logotype Text',
                            '3d-text': 'Text',
                            badge: 'Text',
                          }[currentStyle?.id]
                        }
                      </p>
                      <button onClick={handleRemoveMainText}>
                        <CloseIcon className='w-[15px] h-[15px]' />
                      </button>
                    </div>
                  )}
                  {currentStyle?.id === 'monogram' && yourMainLetter?.value && (
                    <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between pr-[5px] shrink-0'>
                      <div className='flex items-center justify-center h-full w-[24px] bg-app-green rounded-l-[8px]'>
                        <p className='text-[16px] text-[#08432B] pl-[2px]'>
                          {yourMainLetter.value}
                        </p>
                      </div>
                      <p className='font-roboto text-style-text text-[12px]'>
                        Main letter
                      </p>
                      <button onClick={handleRemoveMainLetter}>
                        <CloseIcon className='w-[15px] h-[15px]' />
                      </button>
                    </div>
                  )}
                  {generationStyle !== 'style-transfer' &&
                    currentStyle?.id !== 'logo-type' &&
                    currentStyle?.id !== 'monogram' &&
                    currentStyle?.id !== '3d-text' && (
                      <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between pr-[5px] shrink-0'>
                        <div className='bg-[#42414A] w-[24px] h-[24px] rounded-l-[8px] overflow-hidden'>
                          <CurrentStyleIcon
                            className={`w-[24px] h-[24px] rounded-l-[8px] ${currentStyle?.icon_classes}`}
                          />
                        </div>
                        <p className='font-roboto text-style-text text-[12px]'>
                          {currentStyle?.name}
                        </p>
                        <button>
                          <CloseIcon className='w-[15px] h-[15px]' />
                        </button>
                      </div>
                    )}
                  {inputImage && (
                    <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between shrink-0'>
                      <div className='bg-[#42414A] rounded-l-[8px]'>
                        <img
                          src={inputImage}
                          className='w-[24px] h-[24px] rounded-l-[8px]'
                          alt='Input Img'
                        />
                      </div>
                      <p className='font-roboto text-style-text text-[12px]'>
                        Input Image
                      </p>
                      <button onClick={handleRemoveInputImage} className='pr-2'>
                        <CloseIcon className='w-[15px] h-[15px]' />
                      </button>
                    </div>
                  )}
                  {(styleImage || styleImageUrl) && (
                    <div className='relative flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between shrink-0'>
                      <div className='bg-[#42414A] rounded-l-[8px]'>
                        <img
                          src={styleImage || styleImageUrl}
                          className='w-[24px] h-[24px] rounded-l-[8px]'
                          alt='Input Img'
                        />
                      </div>
                      <p className='font-roboto text-style-text text-[12px]'>
                        Style Image
                      </p>
                      <button onClick={handleRemoveStyleImage} className='pr-2'>
                        <CloseIcon className='w-[15px] h-[15px]' />
                      </button>
                    </div>
                  )}
                  {config['background']?.colors &&
                    config['background'].colors > 0 &&
                    backgroundColor?.colors[0] && (
                      <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between px-2 shrink-0'>
                        <div
                          className=' w-[14px] h-[14px] rounded-[3px]'
                          style={{
                            backgroundColor: backgroundColor?.colors[0]?.color,
                          }}
                        ></div>
                        <p className='font-roboto text-style-text text-[12px]'>
                          BG Color
                        </p>
                        <button onClick={handleRemoveBGColor}>
                          <CloseIcon className='w-[15px] h-[15px]' />
                        </button>
                      </div>
                    )}
                  {config['palette']?.colors &&
                    config['palette'].colors > 0 &&
                    paletteColor?.colors.length > 0 && (
                      <div className='flex h-[24px] w-auto bg-overlay-bg rounded-[8px] gap-[6px] items-center justify-between px-2 shrink-0'>
                        <div className='w-[14px] h-[14px] rounded-[3px] flex overflow-hidden'>
                          {color_keys.map((key, index) => {
                            return (
                              <React.Fragment key={index}>
                                {style_config[key]?.colors
                                  ?.filter((c) => !!c)
                                  .map((item, innerIndex) => (
                                    <div
                                      className='grow w-full'
                                      key={innerIndex}
                                      style={{
                                        background: item.color,
                                      }}
                                    ></div>
                                  ))}
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <p className='font-roboto text-style-text text-[12px]'>
                          Color Palette
                        </p>
                        <button onClick={handleRemovePaletteColor}>
                          <CloseIcon className='w-[15px] h-[15px]' />
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <TextAreaWithImportance
              value={value}
              placeholder='Add your text here, describing the content you want to generate.'
              updateValue={updateValue}
              innerHtml={innerHtml}
              textAreaRef={textAreaRef}
              importanceRef={importanceRef}
            />
          )}

          <div className='flex flex-col-reverse md:flex-row gap-3 '>
            {mode === 'v3.0' || mode === 'v4.0' ? (
              <>
                <V3Controls />
              </>
            ) : (
              <StyleSelector />
            )}
            <GenerateButton
              generationRef={generationRef}
              className=''
              setMode={setMode}
            />
          </div>
        </div>
        {showTutorial && (
          <div
            className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300  w-80 rounded-md'
            ref={setTutorialEl}
            style={tutorialPopper.styles.popper}
            {...tutorialPopper.attributes.popper}
          >
            <div
              ref={setTutorialArrowEl}
              style={tutorialPopper.styles.arrow}
              {...tutorialPopper.attributes.arrow}
              className='h-3 w-3 inline-block top-full left-1/2 '
            >
              <div className='w-full h-full bg-style-method-enabled -translate-x-1/2 -translate-y-1/2 rotate-45 border-select-border border-solid border-0 border-b border-r' />
            </div>
            <div className='flex items-center justify-start gap-2'>
              <span>
                <InfoIcon />
              </span>
              <span className='text-title-white text-sm font-roboto'>
                First, you need a prompt!
              </span>
            </div>
            <div className='font-roboto text-input-color text-xs '>
              To generate images, you need a prompt, try describing what you
              want Logo Diffusion to generate. If you are uploading your own
              image, describe your image and the style you want it redesigned
              in.
            </div>
            <div className='flex items-center justify-end '>
              <button
                className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                onClick={closeTutorialModal}
                type='button'
              >
                Got It
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PromptInput;
