import environment from '../helpers/envProvider';

const PAYMENT_ENVIRONMENT = environment("REACT_APP_PAYMENT_ENVIRONMENT") || "production";

const SumolingPlans = [
  {
    plan_id: "logodiffusion_tier1",
    name: "Tier 1 License via AppSumo",
    credits: 250,
    paidFeatureUsage: {
      "high-res": 25,
      vectorize: 10,
      "remove-bg": 10,
    },
  },
  {
    plan_id: "logodiffusion_tier2",
    name: "Tier 2 License via AppSumo",
    credits: 750,
    paidFeatureUsage: {
      "high-res": 75,
      vectorize: 30,
      "remove-bg": 30,
    },
  },
  {
    plan_id: "logodiffusion_tier3",
    name: "Tier 3 License via AppSumo",
    credits: 2000,
    paidFeatureUsage: {
      "high-res": 200,
      vectorize: 70,
      "remove-bg": 70,
    },
  },
]

export default SumolingPlans;
