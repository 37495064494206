import { Popover, RadioGroup, Transition, Disclosure } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '../../assets/InfoIconGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SettingsIcon } from '../../assets/styles/style-config/settings-icon.svg';
import { ReactComponent as RandomizeIcon } from '../../assets/styles/style-config/randomize.svg';
import { ReactComponent as CarrotIcon } from '../../assets/CaretDownIcon.svg';
import { ReactComponent as CaretDownIconV4 } from '../../assets/caret-up-v4.svg';
import {
  partialUpdateStatePayload,
  resetStyleConfig,
  updateConfigTutorial,
  updateContentStyle,
  updateShowStyleSelector,
  updateShowStyleSelectorTutorial,
} from '../../reducers/formReducer';
import STYLES, { NoneIcon, V4_GROUPS } from './content-styles';
import useGenerationEndpoint from './useGenerationEndpoint';
import StyleSettings from './style-config/StyleSettings';
import { CONFIG_PROPS, STYLE_SETTINGS } from './style-config/constants';
import Tooltip from '../../components/Tooltip';
import { V4_STYLES } from './v4-content-styles';
import BaseTooltip from '../base/BaseTooltip';

const isSettingsDisabled = (method, style) => {
  if (STYLE_SETTINGS[method]?.[style] === undefined) return true;
  return (
    STYLE_SETTINGS[method]?.[style].filter((c) => c.section_id !== 'colors')
      .length === 0
  );
};

const TOOLTIP_DATA_LOGO = {
	'v4/waterfall': (
		<ul className="text-white text-xs list-disc space-y-1 pl-3">
			<li>Good at creating minimalist logos and text</li>
			<li>Can be unpredictable but often creative</li>
			<li>Results might be too minimalist at times</li>
			<li>Averages 35 to 45 seconds per request of 4 images</li>
		</ul>
	),
	'v4/xl': (
		<ul className="text-white text-xs list-disc space-y-1 pl-3">
			<li>Good at creating detailed logos</li>
			<li>Follows prompts more closely</li>
			<li>May offer fewer options with very specific requests</li>
			<li>Averages 40-60 seconds per request of 4 images</li>
		</ul>
	),
}

const TOOLTIP_DATA_ART = {
	'v4/waterfall': (
		<ul className="text-white text-xs list-disc space-y-1 pl-3">
			<li>Better at creating detailed images</li>
			<li>Perfect for realistic styles</li>
			<li>Can get too creative and might result in weird images</li>
			<li>May not follow the prompt precisely at times</li>
		</ul>
	),
	'v4/bolt': (
		<ul className="text-white text-xs list-disc space-y-1 pl-3">
			<li>Better at creating detailed images</li>
			<li>Perfect for realistic styles</li>
			<li>Can get too creative and might result in weird images</li>
			<li>May not follow the prompt precisely at times</li>
		</ul>
	),
	'v4/xl': (
		<ul className="text-white text-xs list-disc space-y-1 pl-3">
			<li>Better at creating 2D art, and illustrations</li>
			<li>Offers more contrast and defined images instead of small details</li>
			<li>Better at following your prompts precisely</li>
			<li>Better understanding of artistic styles and concepts</li>
		</ul>
	),
}

const V4StyleSelector = (props) => {
  const dispatch = useDispatch();

  const { method, generationStyle, setMethod } =
    useGenerationEndpoint();

	const [anchorEl, setAnchorEl] = useState();
  const [popperEl, setPopperEl] = useState();
  const [tutorialEl, setTutorialEl] = useState();
  const [tutorialArrowEl, setTutorialArrowEl] = useState();

  const [clickedIndex, _setClickedIndex] = useState('logo-design');
	const currentGroupRef = useRef('logo-design');
	const setClickedIndex = (x) => {
		currentGroupRef.current = x;
		_setClickedIndex(x);
	}
  const [filteredStyle, setFilteredStyle] = useState(STYLES[method]);

	const tooltipData = clickedIndex === 'logo-design' ? TOOLTIP_DATA_LOGO : TOOLTIP_DATA_ART;

  const handleButtonClick = (index) => {
    setClickedIndex(index);
  };

  useEffect(() => {
    if (clickedIndex) {
      const _filteredStyle = STYLES[method].filter(
        (it) => it.group === clickedIndex
      );
      setFilteredStyle(_filteredStyle);
    }
  }, [clickedIndex]);


  let tutorialPopper = usePopper(popperEl, tutorialEl, {
    placement: 'right',
		strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: tutorialArrowEl,
          padding: -50,
        },
      },
    ],
  });

  const style = useSelector((state) => state.form.payload.style);

  const isOpen = useSelector((state) => state.form.showStyleSelector);
  useEffect(() => {
    if (isOpen) {
      // anchorEl?.click();
      dispatch(updateShowStyleSelector(false));
    }
  }, [isOpen]);

  const handleCloseTutorial = () => {
    dispatch(updateShowStyleSelectorTutorial(false));
  };

  const showTutorial = useSelector(
    (state) => state.form.showStyleSelectorTutorial
  );

  const currentStyle = useMemo(
    () => STYLES[method].find((item) => item.id === style),
    [method, style]
  );
  const CurrentStyleIcon = useMemo(
    () => currentStyle?.icon_preview || currentStyle?.icon || NoneIcon,
    [currentStyle]
  );

	useEffect(() => {
		if (currentGroupRef.current !== currentStyle?.group && currentStyle?.group) {
			setClickedIndex(currentStyle.group)
		}
	}, [currentStyle])

  const style_config = useSelector((state) => state.form.payload.styleConfig);
  const settingsDisabled = isSettingsDisabled(method, style);

  const handleStyle = (value) => {
    const style = V4_STYLES.find((item) => item.id === value);
    console.log('Setting endpoint', style.endpoint);
    dispatch(partialUpdateStatePayload({ style: value }));
    setMethod(style.endpoint);

    if (!!CONFIG_PROPS[method]?.[value] && !isSettingsDisabled(method, value)) {
      setTimeout(() => {
      });
    }
  };
  const PanelAttributes = useMemo(() => {
    return {
      className: 'flex flex-col gap-4 py-2 ',
    };
  }, []);

	const [selectedType, setSelectedType] = useState("");

	const [aModelEl, setAModelEl] = useState();
	const [bModelEl, setBModelEl] = useState();

  if (generationStyle === 'style-transfer') {
    return (
      <div
        className=' relative rounded-xl bg-style-bg py-[9px] p-2 flex flex-row justify-between items-center font-poppins outline-none text-sm text-select-label-disabled f
			ont-semibold  '
      >
        <div className='flex items-center gap-4 justify-start'>
          <div className='flex flex-col items-start font-sans-pro text-[15.13px] font-semibold'>
            <span className='flex'>
              Style Categories
              <Tooltip
                title='Style categories cannot be used alongside a style image'
                position='right'
              >
                <InfoIcon className='w-[20px] h-[20px] ml-1' />
              </Tooltip>
            </span>
            <p className='truncate text-icon-text-color font-sans-pro text-[10px] grow text-center'>
              {currentStyle?.name}
            </p>
          </div>
        </div>
        <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
      </div>
    );
  }

  return (
    <Disclosure
      className={`relative rounded-xl px-3 bg-style-bg py-[9px] p-2 flex flex-col`}
      as='div'
      defaultOpen={true}
			ref={setPopperEl}
    >
      <Disclosure.Button
        className={`font-poppins outline-none text-sm
        text-white font-semibold flex items-center justify-between`}
				ref={setAnchorEl}
      >
        <div className='flex items-center gap-4 justify-start'>
          <div className='w-7 h-7 rounded-[4px] overflow-hidden'>
            <CurrentStyleIcon
              className={`w-7 h-7  ${currentStyle?.icon_preview_classes}`}
            />
          </div>
          <div className='flex flex-col items-start font-sans-pro text-[15.13px] font-semibold'>
            <span className='flex'>
              Style Categories
              <Tooltip title='style categories' position='right'>
                <InfoIcon className='w-[20px] h-[20px] ml-1' />
              </Tooltip>
            </span>
            <p className='truncate text-icon-text-color font-sans-pro text-[12px] grow text-center flex gap-2' onClick={(e) => e.stopPropagation()}>
							{currentStyle?.a_endpoint !== undefined && currentStyle?.b_endpoint !== undefined && (
								<RadioGroup value={method} onChange={(v) => setMethod(v)} as="div" className="flex flex-row gap-2 items-center">
									<RadioGroup.Label>
										Model: 
									</RadioGroup.Label>
									<RadioGroup.Option
										ref={setAModelEl}
										className={({ active, checked }) =>
											` flex items-center font-bold text-sm
											${
												checked
													? "bg-app-green  text-switch-label-green "
													: "bg-app-bg-gray  text-deactive "
											}
											flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ${
													'ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed'
											}`
										}
										value={currentStyle.a_endpoint}
										aria-disabled={currentStyle.a_endpoint === null}
										disabled={currentStyle.a_endpoint === null}
									>
										A
									</RadioGroup.Option>
									<RadioGroup.Option
										ref={setBModelEl}
										className={({ active, checked }) =>
											` flex items-center font-bold text-sm
											${
												checked
													? "bg-app-green  text-switch-label-green "
													: "bg-app-bg-gray  text-deactive "
											}
											flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed`
										}
										value={currentStyle.b_endpoint}
										aria-disabled={currentStyle.b_endpoint === null}
										disabled={currentStyle.b_endpoint === null}
									>
										B
									</RadioGroup.Option>

									<BaseTooltip 
										anchorEl={aModelEl}
										placement="bottom"
										boxClasses="mt-3"
										placementArrowClasses="bottom-full"
										placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-t border-l translate-y-1/2"
									>
										{tooltipData[currentStyle.a_endpoint]}
									</BaseTooltip>
									<BaseTooltip
										anchorEl={bModelEl}
										placement="bottom"
										boxClasses="mt-3"
										placementArrowClasses="bottom-full"
										placementArrowElClasses="w-full h-full rotate-45 border-select-border bg-style-method-enabled border-0 border-t border-l translate-y-1/2"
									>
										{tooltipData[currentStyle.b_endpoint]}
									</BaseTooltip>
									{currentStyle.c_endpoint && (
										<RadioGroup.Option
											className={({ active, checked }) =>
												` flex items-center font-bold text-sm
												${
													checked
														? "bg-app-green  text-switch-label-green "
														: "bg-app-bg-gray  text-deactive "
												}
												flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1 aspect-square ui-disabled:bg-carousel-next-count ui-disabled:text-icon-fill ui-disabled:cursor-not-allowed`
											}
											value={currentStyle.c_endpoint}
											aria-disabled={currentStyle.c_endpoint === null}
											disabled={currentStyle.c_endpoint === null}
										>
											C
										</RadioGroup.Option>
									)}
								</RadioGroup>
							)}
              {currentStyle?.name}
            </p>
          </div>
        </div>
        <CaretDownIconV4 className='transition-all duration-300 ui-open:-rotate-90 [&_path]:fill-chevron-icon w-6 h-6 max-h' />
      </Disclosure.Button>
      <Disclosure.Panel {...PanelAttributes}>
        <div className='flex flex-col gap-[2px]'>
          <div className='flex flex-row w-full h-full gap-[7px] flex-wrap'>
            {V4_GROUPS.map(({ icon: Icon, ...item }, index) => {
              return (
                <button
                  key={index}
                  className={`flex items-center justify-center gap-2 bg-app-bg-gray max-w-[123px] h-[24px] px-[10px] rounded-[4px] text-[10px] font-inter flex-grow whitespace-nowrap ${
                    item.id === clickedIndex
                      ? 'border border-1 border-app-green'
                      : ''
                  }`}
                  onClick={() => handleButtonClick(item.id)}
                >
                  <div
                    className={`${
                      item.id === clickedIndex
                        ? 'text-white font-bold'
                        : 'text-icon-text-color'
                    }`}
                  >
                    {item.name}
                  </div>
									{item?.types && (
										<RadioGroup value={selectedType} onChange={setSelectedType} 
											className="flex gap-1 items-center">
											{item?.types.map(t => (
												<RadioGroup.Option
													key={t}
													className={({ active, checked }) =>
														` flex items-center font-bold text-xs
														${
															checked
																? "bg-app-green  text-white "
																: "bg-overlay-bg  text-icon-text-color "
														}
														flex cursor-pointer focus:outline-none overflow-hidden rounded-sm px-1`
													}
													value={t}
												>
													{t}
												</RadioGroup.Option>
											))}
										</RadioGroup>
									)}
                </button>
              );
            })}
          </div>
          <div
            className={`w-full h-full transition-all duration-500 max-h-[175px]  overflow-auto custom-scroll v4-custom-scroll pt-4`}
          >
            <RadioGroup
              value={style}
              onChange={handleStyle}
              className='grid grid-cols-3 gap-2 [&>div]:text-style-text [&>div]:text-xs pr-1 w-full'
							// style={{
							// 	gridTemplateRows: `repeat(${Math.ceil(filteredStyle.length / 3)}, minmax(0, 1fr))`
							// }}
            >
              {filteredStyle.map(({ icon: Icon, ...item }) => {
                return (
                  <RadioGroup.Option
                    disabled={item.disabled}
                    key={item.id}
                    value={item.id}
                    className='aspect-square relative cursor-pointer ui-disabled:cursor-not-allowed rounded-lg overflow-hidden bg-app-bg-gray border-2 border-solid border-transparent ui-checked:border-app-green'
                  >
                    <div className='flex items-center justify-center rounded-[8px] w-full aspect-square overflow-hidden box-content '>
                      <Icon className={`${item.icon_classes} `} />
                    </div>
                    <div className='absolute bottom-0 left-0 right-0 px-2 py-1 bg-black/60 flex text-[0.6rem] [line-height:0.8rem] text-icon-text-color-v4 font-inter line-clamp-2 text-ellipsis whitespace-normal text-center'>
                      {item.name}
                    </div>
                    {item.beta && (
                      <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                        Beta
                      </div>
                    )}
                    {item.new && (
                      <div className='bg-app-green/10 rounded-[0.2rem] px-1 text-app-green text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                        New
                      </div>
                    )}
                    {item.soon && (
                      <div className='bg-white/10 rounded-[0.2rem] px-1 text-style-soon-badge text-[0.6rem] [line-height:0.8rem] absolute top-1 right-[0.125rem]'>
                        Soon
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              })}
            </RadioGroup>
          </div>

          {showTutorial && (
            <div
              className='flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border p-2 py-3 relative transition-all duration-300 ml-4 w-60 rounded-md z-[100]'
              ref={setTutorialEl}
              style={tutorialPopper.styles.popper}
              {...tutorialPopper.attributes.popper}
            >
              <div
                ref={setTutorialArrowEl}
                style={tutorialPopper.styles.arrow}
                {...tutorialPopper.attributes.arrow}
                className='h-3 w-3 inline-block right-full '
              >
                <div className='w-full h-full bg-style-method-enabled -translate-y-10 translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-b border-l'></div>
              </div>
              <div className='flex items-center justify-start gap-2'>
                <span>
                  <InfoIcon />
                </span>
                <span className='text-title-white text-sm font-roboto'>
                  Choose a style first.
                </span>
              </div>
              <div className='font-roboto text-input-color text-xs '>
                Before you can generate your first image, you need to pick a
                logo design style.
              </div>
              <div className='flex items-center justify-end mt-1'>
                <button
                  className='capitalize text-white rounded-lg bg-button-blue font-poppins text-xs font-semibold py-1 px-3'
                  onClick={handleCloseTutorial}
                >
                  Got It
                </button>
              </div>
            </div>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default V4StyleSelector;
